import React from 'react'
import clsx from 'clsx'
import {
	iconRound,
	iconSmall,
	iconMedium,
	iconLarge,
	insideBtn,
	insideBtnFa,
	iconOutline,
} from './StyledIcon.module.scss'

const icons = {
	right: 'ss-icon ss-black-tie-bold ss-right',
	left: 'ss-icon ss-black-tie-bold ss-left',
	videos: 'ss-icon ss-black-tie-solid ss-video',
	articles: 'ss-icon ss-black-tie-solid ss-alignleft',
	'in-the-news': 'ss-icon ss-black-tie-solid ss-alignleft',
	'client-news': 'ss-icon ss-black-tie-solid ss-megaphone',
	'thought-leadership': 'ss-icon ss-black-tie-solid ss-megaphone',
	'case-studies': 'ss-icon ss-black-tie-solid ss-alignleft',
	'firm-news': 'ss-icon ss-black-tie-solid ss-megaphone',
	'press-releases': 'ss-icon ss-black-tie-solid ss-megaphone',
	events: 'ss-icon ss-black-tie-solid ss-calendar',
	podcasts: 'ss-icon ss-pika ss-mic',
	webinar: '',
	time: 'ss-icon ss-black-tie-solid ss-clock',
	play: 'ss-icon ss-standard ss-play',
	down: 'ss-icon ss-black-tie-bold ss-down',
	up: 'ss-icon ss-black-tie-bold ss-up',
	home: 'ss-icon ss-black-tie-solid ss-home',
	search: 'ss-icon ss-black-tie-solid ss-search',
	largeSearch: 'ss-icon ss-black-tie-bold ss-search',
	facebook: 'fa-brands fa-square-facebook',
	twitter: 'fa-brands fa-x-twitter',
	bluesky: 'fa-brands fa-bluesky',
	linkedin: 'fa-brands fa-linkedin-in',
	vimeo: 'fa-brands fa-vimeo-v',
	plus: 'ss-icon ss-black-tie-bold ss-plus',
	email: 'ss-icon ss-black-tie-solid ss-mail',
	vcard: 'ss-icon ss-standard ss-addcalendar',
	pdf: 'ss-icon ss-standard ss-download',
	peopleEmail: 'ss-icon ss-standard ss-envelope',
	grid: 'ss-icon ss-black-tie-solid ss-grid',
	list: 'ss-icon ss-black-tie-solid ss-list',
	filter: 'ss-icon ss-black-tie-solid ss-list',
	delete: 'ss-icon ss-black-tie-solid ss-delete',
	user: 'ss-icon ss-black-tie-solid ss-user',
	globe: 'ss-icon ss-black-tie-solid ss-globe',
	wrench: 'ss-icon ss-black-tie-solid ss-wrench',
	flowchart: 'ss-icon ss-black-tie-solid ss-flowchart',
	location: 'ss-icon ss-black-tie-solid ss-location',
	playcircle: 'ss-icon ss-black-tie-solid ss-playcircle',
	apple: 'fa-brands fa-apple',
	googlePlay: 'fa-brands fa-google-play',
	spotify: 'fa-brands fa-spotify',
}

const StyledIcon = ({
	round,
	color,
	iconColor,
	name,
	btn,
	size = 'small',
	outline,
	...props
}) => {
	const classes = clsx(
		round && iconRound,
		round && size === 'small' ? iconSmall : null,
		round && size === 'medium' ? iconMedium : null,
		round && size === 'large' ? iconLarge : null,
		color && `${color}`,
		outline && iconOutline
	)

	const iconClasses = clsx(
		icons[name],
		iconColor ? `icon-${iconColor}` : 'icon-white',
		btn && insideBtn,
		btn && icons[name].includes('fa-') ? insideBtnFa : '',
	)

	return (
		<>
			{classes ? (
				<span className={classes}>
					<i className={iconClasses}></i>
				</span>
			) : (
				<i className={iconClasses}></i>
			)}
		</>
	)
}

export default StyledIcon
